export const environment = {
	name: 'qa',
	production: false,
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
	openidClientId: 'urn:agenda:clientid:web_agendadealer:qa',
	openidResource: 'urn:agenda:resource:web_agendadealer:qa',
	openidRedirectUrl: '',
	bffUrl: 'https://api-qat.service.ford.com/agenda/manage-reservation-bff',
	dealerTransformationUrl: 'https://qa.mfe.agenda.ford.com/home',
	workshopUrl: 'https://qa.mfe.agenda.ford.com/workshop'
};
